import React, { useEffect } from "react";
import "./styles.scss";
import { string, arrayOf, shape, object, bool, number } from "prop-types";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { checkGif } from "../../../../assets/imgs/index.js";
import { external } from "../../../../services/linkHelper.js";
import mallsLinks from "./mallUrlPerGroupId.json";

export default function GeneralSucessPage({
  cupomImg,
  buttons,
  location,
  params,
  customerData,
  groupBusinessId,
  hasValidOffer,
  customerName = "",
}) {
  const navigate = useNavigate();
  const url = mallsLinks[params.businessId];

  useEffect(() => {
    if (!url) {
      return;
    }

    if (location.hash !== '#totem') {
      window.location.href = url;
      return;
    }
    const timer = setTimeout(() => {
      navigate(`${external.GSWELCOME}/${params.businessId}${location.hash}`)
    }, 60000);
    return () => clearTimeout(timer);

    // const timer = setTimeout(() => {
    //   window.location.href = url;
    // }, 7000);

    // return () => clearTimeout(timer);
  }, []);

  if (mallsLinks[params.businessId] && location.hash === '#totem') {
    return (
      <iframe src={mallsLinks[params.businessId]} style={{ width: '100%', height: '100vh', border: 0 }} />
    )
  }

  return (
    <div className="welcome-page">
      <div className="welcome-success">
        <div>
          <img
            alt="business logo"
            className="align-start email-business-img business-logo-img"
            src={checkGif}
            width="100%"
            height={160}
          />
        </div>
        {customerName ? (
          <>
            <h1>{`Parabéns, ${customerName}!`}</h1>
            <p className="general-success-optin-msg">
              Em instantes você poderá receber ofertas exclusivas das suas
              marcas favoritas!
            </p>
            {url && (
              <p className="general-success-optin-msg">
                Enquanto isso, vamos te redirecionar para a vitrine virtual em
                alguns segundos...
              </p>
            )}
          </>
        ) : (
          <>
            <h3>Cpf encontrado!</h3>
            <p className="general-success-optin-msg">
              Seu cpf já consta em nossa base de dados, então a qualquer momento
              você poderá receber ofertas exclusivas das suas marcas favoritas!
            </p>
            {url && (
              <p className="general-success-optin-msg">
                Estamos te redirecionando para a vitrine virtual em alguns
                segundos...
              </p>
            )}
          </>
        )}
        {cupomImg && location.state.isNewOptIn && (
          <div className="div-image-cupom">
            <img
              className="image-cupom business-logo-img"
              alt="Cupom promocional"
              src={cupomImg}
            />
          </div>
        )}

        {groupBusinessId !== 1463 && hasValidOffer && (
          <div
            className="btn btn-welcome btn-welcome-link link-not-decored"
            onClick={() => {
              navigate(`${external.OFFERS}/${params.businessId}`, {
                state: {
                  cpf: customerData,
                  groupBusinessId,
                },
              });
            }}
          >
            Ver ofertas
          </div>
        )}

        {buttons?.map((buttonData, index) => (
          <div key={`btn-welcome-${index}`} className="welcome-right">
            <form className="form optin">
              <a
                key={`button-page-${index}-31`}
                className="btn btn-welcome btn-welcome-link link-not-decored"
                href={buttonData.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonData.label}
              </a>
            </form>
          </div>
        ))}
      </div>
    </div>
  );
}

GeneralSucessPage.propTypes = {
  cupomImg: string,
  buttons: arrayOf(
    shape({
      label: string,
      url: string,
    })
  ),
  location: shape({
    state: shape({
      isNewOptIn: bool,
    }),
  }),
  params: object,
  customerData: string,
  groupBusinessId: number,
  hasValidOffer: bool,
  customerName: string,
};
