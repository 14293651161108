import React from "react";
import "./styles.css";
import {currency, numberFormat} from "../../../../services/utils.jsx";

// eslint-disable-next-line react/prop-types
function ValuesCard({emoji, emojiSize, emojiAlt, value, valueType="currency", description}) {
  return (
    <div className="values-card-container">
      {emoji && (
        <div className="model-header-emoji">
          {typeof emoji === "string" ? (
            <img src={emoji} width={emojiSize} height={emojiSize} alt={emojiAlt}/>
          ) : (
            emoji
          )}
        </div>
      )}
      <span className="value-info">{valueType === "currency" ? currency(value) : valueType ==="qnt" ? numberFormat(value) : value}</span>
      <span className="value-description">{description}</span>
    </div>
  );
}

export default ValuesCard;
