import React from "react";
import "./styles.scss";
import { shape, string, bool, number } from "prop-types";
import imgBusiness from "../../../../assets/imgs/business.svg";
import WelcomeSkeleton from "../Skeletons/WelcomeSkeleton.jsx";
import { generalLogo, maiaGenLogo } from "../../../../assets/imgs/index.js";

export default function Logo({
  data,
  loading,
  imgAlt = "Assinar ofertas",
  imgClassName = "business-logo-img",
  isGeneral = false,
  groupId = 0,
}) {
  if (loading) return <WelcomeSkeleton />;

  function getLogo() {
    if (!isGeneral) return { src: data.image || imgBusiness, style: undefined };
    if (groupId === 3235) return { src: maiaGenLogo, style: { width: 290 } }; // Parque Shopping Maia
    return { src: generalLogo, style: { width: 250 } };
  }

  return (
    <div className="page-logo-image">
      <img
        src={getLogo().src}
        alt={imgAlt}
        className={imgClassName}
        style={getLogo().style}
      />
    </div>
  );
}

Logo.propTypes = {
  data: shape({
    image: string,
  }),
  loading: bool.isRequired,
  imgAlt: string,
  imgClassName: string,
  isGeneral: bool,
  groupId: number,
};
