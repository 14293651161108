import React, {useEffect, useState} from "react";
import "./styles.css";
import {CircularProgress} from "@mui/material";
import ValuesCard from "./components/ValuesCard/index.jsx";
import {getGenBiudCoupon} from "../../services/apis/biud/fetchs.js";
import {escapeParentheses, formatDate, removeHtml} from "../../services/utils.jsx";
import {emojiClockNine, emojiHourglass, emojiMoneyNote} from "../../assets/imgs/index.js";

function GenBiudCoupon() {
	const [coupon, setCoupon] = useState({});
	const [loading, setLoading] = useState(true);

	const fetchCoupons = async () => {
		try {
			setLoading(true);
			const res = await getGenBiudCoupon({id: 1});
			setCoupon(res?.results?.[0] || {});
		} catch (err) {
			console.log(err.message);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchCoupons();
	}, []);

	if (loading) {
		return <div className="gen-biud-coupon-loading-container">
			<CircularProgress color="primary" size={30}/>
		</div>;
	}

	return (
		<div className="gen-biud-coupon-container">
			<div className="coupon-container anime-scale-in-center">
				<h3>GenBiud Cupom</h3>
				<div
					className="coupon-card-image"
					style={{
						background: `url(${escapeParentheses(coupon?.coupon_image)}) lightgray 50% / contain no-repeat`,
					}}
				/>
				<div className="coupon-card-content">
					<h4>{coupon?.name}</h4>
					<p>{removeHtml(coupon?.description)}</p>
					<div className="values-cards-container">
						<ValuesCard emoji={emojiMoneyNote} description="Valor mínimo" value={coupon?.buy_value_min}/>
						<ValuesCard emoji={emojiMoneyNote} description="Valor máximo" value={coupon?.buy_value_max}/>
					</div>
					<div className="values-cards-container">
						<ValuesCard
							emoji={emojiHourglass}
							description="Quantidade restante"
							value={coupon?.amount}
							valueType="qnt"
						/>
						<ValuesCard
							emoji={emojiClockNine}
							emojiSize={24}
							description="Data de expiração"
							value={formatDate(coupon?.expiration_date, "DD/MM/YYYY") || "--/--/----"}
							valueType="date"
						/>
					</div>
					<div className="scratch-card-image-container">
						<p>{coupon?.code}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GenBiudCoupon;
