import React, { useEffect, useState } from "react";
import "./styles.scss";
import Components from "./components";
import { LinearProgress } from "@mui/material";
import { external } from "../../services/linkHelper.js";
import { useLocation, useNavigate } from "react-router-dom";
import { getSegments } from "../../services/apis/generalLeads/fetchs.js";
import { saveLeadSegment } from "../../services/apis/generalLeads/requests.js";
import mallsLinks from "../Welcome/Success/GeneralSucessPage/mallUrlPerGroupId.json";
import { useParams } from "react-router-dom";

function GeneralBrands() {
  const location = useLocation();
  const params = useParams();

  const data = location.state;
  const requestData = data?.requestData || {};
  const checkInData = data?.checkInData || {};

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);

  const [segmentsList, setSegmentsList] = useState([]);
  const [loadingSegments, setLoadingSegments] = useState(true);

  const fetchSegmentsList = async () => {
    try {
      setLoadingSegments(true);
      const res = await getSegments();
      setSegmentsList(res?.data?.segments || []);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoadingSegments(false);
    }
  };

  const requestSaveSegments = async () => {
    try {
      setRequestLoading(true);
      const checkInId = checkInData?.id || 0;
      const res = await saveLeadSegment({
        checkInId: checkInId,
        segmentIds: selectedSegments,
      });

      if (res.data?.message === "Segments saved.") {
        if (location.hash !== '#totem') {
          window.location.href = mallsLinks[params.businessId];

          return;
        }

        navigate(`${external.GSSUCCESS}/${data.id}${location.hash}`, {
          state: {
            cpf: requestData.cpf,
            name: requestData?.name || "",
            isNewOptIn: true,
            isGeneral: true,
          },
        });
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    fetchSegmentsList();
  }, []);

  const filteredDepartments = search
    ? segmentsList.filter((brand) =>
        brand.name.toUpperCase().includes(search.toUpperCase())
      )
    : segmentsList;

  if (loadingSegments)
    return (
      <LinearProgress
        sx={{
          backgroundColor: "white",
          "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" },
        }}
      />
    );

  return (
    <section className="general-brands-container">
      <Components.Header
        title={`${data?.name || ""},`}
        subtitle="conta pra gente quais departamentos te interessam hoje!"
      />
      <Components.BrandSearch
        setSearch={setSearch}
        placeHolder="Faça uma busca"
      />
      <Components.BrandsList>
        {filteredDepartments.length ? (
          filteredDepartments.map((brand) => (
            <Components.BrandCard
              key={brand.id}
              id={brand.id}
              brandTitle={brand.name}
              data-testid="brand-card"
              onClick={() =>
                setSelectedSegments((old) => {
                  if (old.includes(brand.id)) {
                    return old.filter((item) => item !== brand.id);
                  }
                  return [...old, brand.id];
                })
              }
              className={
                selectedSegments.includes(brand.id) ? "brand-item-selected" : ""
              }
            />
          ))
        ) : (
          <span className="gs-brand-list-item-notfound">
            Nenhum departamento encontrado
          </span>
        )}
      </Components.BrandsList>
      <Components.Footer
        selectedBrands={selectedSegments}
        loading={requestLoading}
        buttonAction={() => requestSaveSegments()}
      />
    </section>
  );
}

export default GeneralBrands;
