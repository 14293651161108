import React from "react";
import "./styles.scss";
import {bool, func, object, string} from "prop-types";
import InputMask from "react-input-mask";
import SelectDateSplitted from "../../../../components/SelectDateSplitted/index.jsx";
import Select from "../../../../components/Select/index.jsx";
import {Checkbox} from "@mui/material";

export default function GeneralOptinForm({
	customerData,
	changeCustomerData,
	leadExistedData,
	invalidPhone,
	errorMsg,
	setCustomerData,
}) {
	return (
		<>
			{leadExistedData?.name && (
				<p>
					{`Olá ${leadExistedData?.name.split(" ")[0]}, confirme seus dados:`}{" "}
				</p>
			)}
			<div className="general-optin-form-container">
				<p className="gs-hotlead-label">Nome</p>
				<input
					name="name"
					data-testid="name-input"
					placeholder="Seu nome"
					className="default-input"
					value={customerData.name}
					onChange={(e) => changeCustomerData(e)}
				/>
				<p className="gs-hotlead-label">Celular</p>
				<InputMask
					name="phone"
					id="phone-input"
					data-testid="phone-input"
					className={`default-input ${
						invalidPhone ? "input-required-invalid" : ""
					}`}
					placeholder="Seu celular"
					value={customerData.phone}
					onChange={(e) => changeCustomerData(e)}
					mask="(99) 99999-9999"
				/>
				<Select
					label="Gênero"
					name="gender"
					value={customerData.gender}
					onChange={(e) => changeCustomerData(e)}
					style={{
						borderColor: "rgb(118, 118, 118)",
						height: "42px",
						borderRadius: "8px",
						fontSize: "14px",
					}}
					options={[
						{name: "Selecionar", value: ""},
						{name: "Masculino", value: "Masculino"},
						{name: "Feminino", value: "Feminino"},
						{name: "Outro", value: "Outro"},
						{name: "Prefiro não informar", value: "Nao_informado"},
					]}
				/>
				<p className="gs-hotlead-label">Qual sua data de nascimento?</p>
				<SelectDateSplitted
					selectedDate={customerData.bornAt}
					onDateChange={(date) => {
						setCustomerData((old) => ({...old, bornAt: date}));
					}}
				/>
				<p
					className="privacy-policy-old-container"
				>
					<Checkbox size="small" name="useTerms" checked={customerData.useTerms}
					          onChange={(e) => changeCustomerData(e, true)}/>
					<div className="privacy-policy-text-container">
						Concordo com os{" "}
						<a
							id={`btn-signup-useTerms`}
							className="button-privacy-link color-blue"
							target="_blank"
							href={"https://gen.com.br/transparencia/termosecondicoes"}
							rel="noopener noreferrer"
						>
							{" "}
							Termos de uso{" "}
						</a>
						<span>e </span>
						<a
							id={`btn-signup-privacy-policy`}
							className="button-privacy-link color-blue"
							target="_blank"
							href={"https://gen.com.br/transparencia/privacidade"}
							rel="noopener noreferrer"
						>
							Política de Privacidade
						</a>
					</div>
				</p>
				{errorMsg && (
					<span className="label-error text-center anime-scale-in-center">{`Ops, ${errorMsg}...`}</span>
				)}
			</div>
		</>
	);
}

GeneralOptinForm.propTypes = {
	changeCustomerData: func.isRequired,
	invalidPhone: bool.isRequired,
	errorMsg: string.isRequired,
	customerData: object.isRequired,
	leadExistedData: object.isRequired,
	setCustomerData: func.isRequired,
};
