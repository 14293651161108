import biudApi from "./api";

export const getBusinessPage = async ({ businessId }) => {
  const res = await biudApi.get(`/opt-in/page/${businessId}`);
  return res.data;
};

export const getBusinessGoodbyePage = async ({ businessId }) => {
  const res = await biudApi.get(`/opt-out/page/${businessId}`);
  return res.data;
};

export const getBusinessGroupGoodbyePage = async ({ businessGroupId }) => {
  const res = await biudApi.get(`/opt-out/group/page/${businessGroupId}`);
  return res.data;
};

export const getUserAlreadyRegistered = async ({ businessId, cpf }) => {
  const res = await biudApi.get(`/opt-in/${businessId}/customer/${cpf}`);
  return res.data;
};

export const getUserAlreadyRegisteredInGroup = async ({ businessGroupId, cpf }) => {
  const res = await biudApi.get(`/opt-in/group/${businessGroupId}/customer/${cpf}`);
  return res.data;
};

export const getGenBiudCoupon = async ({ id }) => {
  const res = await biudApi.get(`/biud-gen-coupons`, {
    params: { id },
  });
  return res.data;
};