import moment from "moment";
import * as XLSX from 'xlsx'
import md5 from "crypto-js/md5";

export const telefoneMask = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const validarCNPJ = (cnpj) => {
  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;
  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0).toString()) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(1).toString()) return false;

  return true;
};

export const validarCPF = (cpf) => {
  if (cpf === "") return false;

  if (cpf.length !== 11) return false;

  // Elimina cpfs invalidos conhecidos
  if (
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;

  let soma, resto;
  soma = 0;

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export function removerCaracteresEspeciais(value) {
  if (value !== undefined)
    return value.replace(/[^\d]+/g, "");
  return "";
}

export function generateNameHash(mimeType) {
  let mime;
  switch (mimeType) {
    case "image/png":
      mime = "png";
      break;
    case "application/x-pkcs12":
      mime = "pfx";
      break;
    case "text/csv":
      mime = "csv";
      break;
    case "application/zip":
    case "application/octet-stream":
      mime = "zip";
      break;
    case "video/mp4":
      mime = "mp4";
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      mime = "xlsx";
      break;
    default:
      mime = "jpg";
      break;
  }
  const date = moment().format();
  return `${md5(date + Math.random())}.${mime}`;
}

export function getAbbr(text) {
  if (!text) return;
  const arrText = text.split(" ");
  return arrText[0].charAt(0).toUpperCase() + arrText[arrText.length - 1].charAt(0).toUpperCase();
}

export function getFistLetter(text) {
  if (!text) return;
  return text.charAt(0).toUpperCase();
}

export function compareObjects(a, b) {
  return a - b;
}

export function convertBytesToMegaBytes(value) {
  return Math.round((value / 1000 / 1000) * 100) / 100;
}

export function formatPhone(value, parentheses = false) {
  if (value) {
    value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
    value = value.replace(/^(\d{2})(\d)/g, parentheses ? "($1) $2" : "$1 $2"); //Separa dois primeiros dígitos
    value = value.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  }
  return value;
}

export const formatCpf = (value) => {
  if (value) {
    value = value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  return value;
};

export const formatCnpj = (value) => {
  if (value) {
    value = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  return value;
};

export function convertDateToEn(date) {
  return date ? `${date.split("/")[2]}-${date.split("/")[1]}-${date.split("/")[0]}` : null;
}

export function getFullyDayNameByAbbr(abbrDay) {
  switch (abbrDay.toLowerCase()) {
    case "seg":
      return "segunda";
    case "ter":
      return "terça";
    case "qua":
      return "quarta";
    case "qui":
      return "quinta";
    case "sex":
      return "sexta";
    case "sáb":
      return "sábado";
    case "dom":
      return "domingo";
    default:
      break;
  }
}

export function stopAllVideos() {
  try {
    var video = document.querySelector("video");
    if (video) {
      video.pause();
    }
  } catch (err) {
    console.log(err);
  }
}

export function capitalize(text) {
  return text.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
}

export function upperCaseFirstLetter(text) {
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function phraseFirstLetterUpperCase(text) {
  if (!text) return "";
  const words = text.toLowerCase().split(" ");
  const res = words.map((word) => {
    return word[0] ? (word = word[0].toUpperCase() + word.substr(1)) : "";
  });
  return res.join(" ");
}

export function phraseFirstLetterUpperCaseSpecial(text) {
  if (!text) return "";

  const ignoredWords = ['a', 'e', 'i', 'o', 'u', 'da', 'de', 'do'];
  const words = text.toLowerCase().split(" ");

  const res = words.map((word) => {
    if (ignoredWords.includes(word)) return word;
    return word[0] ? (word = word[0].toUpperCase() + word.substr(1)) : "";
  });

  return res.join(" ");
}

export function numberFormat(value) {
  if (value === undefined || value === null) return "0";
  return new Intl.NumberFormat("pt-BR").format(value);
}

export function getDateNow(format = "YYYY-MM-DD") {
  return moment().format(format);
}

export function formatUtcDate(date, format = "YYYY-MM-DD") {
  if (import.meta.env.VITE_ENV === "prod") return moment(date).format(format);
  return moment(date).utc().format(format);
}

export function formatDate(date, format = "YYYY-MM-DD") {
  return date ? moment(date).format(format) : "";
}

export function dateDiff(dateToDiff, typeDiff) {
  const tomorrow = moment().add(1, typeDiff);
  return moment(tomorrow).diff(dateToDiff, typeDiff);
}

export function dateDaysDifftoCurrent(dateToDiff) {
  const currentDate = moment();
  const paramDate = moment(dateToDiff);
  const qntDays = paramDate.diff(currentDate, 'days');

  return qntDays < 0 ? qntDays * (-1) : qntDays;
}

export function dateAutoDateOrTime(dateToDiff) {
  const qntDays = dateDaysDifftoCurrent(dateToDiff);
  switch (qntDays) {
    case 0:
      return formatDate(dateToDiff, 'HH:mm');
    case 1:
      return 'Ontem';
    default:
      return formatDate(dateToDiff, 'DD/MM/YYYY');
  }
}

export function removeAccent(string) {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function copyOnClick(element, callBackFunc) {
  if (!element) return;
  element.select();
  element.setSelectionRange(0, 99999);
  document.execCommand("copy");
  callBackFunc();
}

export function getWeekDayByNumber(weekDayNumber) {
  switch (weekDayNumber) {
    case 0:
      return "Domingo";
    case 1:
      return "Segunda-feira";
    case 2:
      return "Terça-feira";
    case 3:
      return "Quarta-feira";
    case 4:
      return "Quinta-feira";
    case 5:
      return "Sexta-feira";
    case 6:
      return "Sábado";
    default:
      return "--";
  }
}

export function getDayInitialByNumber(weekDayNumber) {
  switch (weekDayNumber) {
    case 0:
      return "D";
    case 1:
      return "S";
    case 2:
      return "T";
    case 3:
      return "Q";
    case 4:
      return "Q";
    case 5:
      return "S";
    case 6:
      return "S";
    default:
      return "--";
  }
}

export function convertBooleanToYesOrNo(value) {
  return value ? "sim" : "não";
}

export function parseFloatFromBRL(value) {
  return parseFloat(value.replace(".", "").replace(",", "."));
}

export function convertObjectToArray(object) {
  return Object.keys(object).map((key) => object[key]);
}

export function convertToCSV(headers, data) {
  const csvRows = [];
  csvRows.push(headers.join(","));
  for (const row of data) {
    const values = Object.keys(row).map((prop) => `"${row[prop]}"`);
    csvRows.push(values.join(","));
  }
  return csvRows.join("\n");
}

export function convertArrayOfObjectToCSVBlob(headers, items) {
  const csv = convertToCSV(headers, items);
  return new Blob([csv], {type: "text/csv;charset=utf-8;"});
}

export function downloadBlob(blob, fileName) {
  // IE 10+
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getGenderLabelByInitial(initial) {
  if (!initial) return;
  if (initial.toUpperCase() === "F") return "Feminino";
  if (initial.toUpperCase() === "M") return "Masculino";
  return "";
}

export function encodeBase64(data) {
  return window.btoa(data);
}

export function decodeBase64(data) {
  return window.atob(data);
}

export function generateWebhookUrl(businessId) {
  return `${import.meta.env.VITE_WEBHOOK_URL}webhooks/${businessId}/whatsapp`
}

export function getCategoryImageByLabel(cat) {
  switch (cat) {
    case "Todos":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-todos-os-clientes.svg").default}
          alt="Emoji contente"
        />
      );
    case "Melhores":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-melhores.svg").default}
          alt="Emoji de óculos escuro"
        />
      );
    case "Novos":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-novos.svg").default}
          alt="Emoji apaixonado"
        />
      );
    case "Leais":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-leais.svg").default}
          alt="Emoji anjinho"
        />
      );
    case "Gastadores":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-gastadores.svg").default}
          alt="Emoji de riqueza"
        />
      );
    case "Quase perdidos":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-quase-perdidos.svg").default}
          alt="Emoji chateado"
        />
      );
    case "Perdidos":
      return (
        <img
          className="x-small table-cell-icon"
          src={require("../assets/imgs/icon-emoji-perdidos.svg").default}
          alt="Emoji chorando"
        />
      );
    default:
      break;
  }
}

export function generateArrayForChart(data) {
  const arrSum = new Array(7).fill(0);
  data.forEach((item) => {
    const dayNumber = moment(item.key).day();
    arrSum[dayNumber] += item.value < 0.9 ? parseFloat(item.value.toFixed(8)) : Math.round(item.value);
  });

  return arrSum;
}

export function getMainGenderArrange(age) {
  if (!parseInt(age)) return '---';

  const min = parseInt(age) - 5;
  const max = parseInt(age) + 5;
  return `${min} - ${max} anos`;
}

export function generateStringBestTime(hour) {
  if (hour === null || hour === undefined || hour < 0) return `--:--`;
  return `${hour}h às ${hour + 1}h`;
}

export function biudersNamesGenerator() {
  const names = [
    "Agatha",
    "Anne",
    "Antonio",
    "Aragão",
    "Carlos",
    "Ciro",
    "Daniela",
    "Eduardo",
    "Fabiano",
    "Felippi",
    "Filipi",
    "Guilherme",
    "Jefferson",
    "JP",
    "Jéssica",
    "Laís",
    "Leonardo",
    "Leonn",
    "Lucas",
    "Marcelo",
    "Matheus",
    "Michelle",
    "Micherly",
    "Patrícia",
    "Pedro",
    "Pollyane",
    "Rafael",
    "Roberta",
    "Romulo",
    "Samuel",
    "Sophia",
    "Thales",
    "Thiago",
    "Vanessa",
    "Victor",
    "Vinicius",
    "Wayne"
  ];

  const randomId = Math.floor(Math.random() * names.length)
  return `${names[randomId]} Biud`;
}

export const shuffleArray = (arr) => {
  for (let i = 0; i < arr.length; i += 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export const arrayChunk = (arr, length) => {
  let chunks = [], i = 0, n = arr.length
  while (i < n) {
    chunks.push(arr.slice(i, i += length))
  }
  return chunks
}


export const exportListToCsv = async (sheet, name = "Ativação") => {
  try {
    const dataAtual = getDateNow("DD-MM-YYYY");
    const horaAtual = getDateNow("HH:mm:ss");

    const fileName = `${name} - Extraído em ${dataAtual} às ${horaAtual}`;

    const ws = XLSX.utils.json_to_sheet(sheet);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } catch (err) {
    console.log(err.message);
  }
}

export function convertClassificationCustomers(classification) {
  switch (classification) {
    case 'NEW':
      return 'Novos';
    case 'LOST':
      return 'Perdidos';
    case 'ALMOST_LOST':
      return 'Quase Perdidos';
    case 'BEST':
      return 'Melhores';
    case 'SPENDER':
      return 'Gastadores';
    case 'LOYAL':
      return 'Leais';
    default:
      return '---';
  }
}

export function convertClassificationCustomersInColor(classification) {
  switch (classification) {
    case 'NEW':
      return [{
        color: 'rgba(166, 38, 191, 0.2)',
        border: 'rgba(166, 38, 191, 1)',
      }];
    case 'LOST':
      return [{
        color: 'rgba(124, 129, 137, 0.2)',
        border: 'rgba(124, 129, 137, 1)',
      }];
    case 'ALMOST_LOST':
      return [{
        color: 'rgba(255, 138, 0, 0.2)',
        border: 'rgba(255, 138, 0, 1)',
      }];
    case 'BEST':
      return [{
        color: 'rgba(0, 149, 255, 0.2)',
        border: 'rgba(0, 149, 255, 1)',
      }];
    case 'SPENDER':
      return [{
        color: 'rgba(41, 204, 95, 0.2)',
        border: 'rgba(41, 204, 95, 1)',
      }];
    case 'LOYAL':
      return [{
        color: 'rgba(216, 22, 86, 0.2)',
        border: 'rgba(216, 22, 86, 1)',
      }];
    default:
      return '---';
  }
}

export const isJustSpace = (text) => /^\s+$/.test(text);

export const haveDoubleSpace = (text) => !(/^(?!.* {2}).+/.test(text));

export const normalizeNameToId = (name) => {
  if (!name) return;
  return name.toLowerCase().trim().replace(/\s/g, "-")
};

export const addSpaceBetweenSlashes = (text) => {
  return text.replace(/\//g, " / ");
}

export const escapeParentheses = (text) => {
  if (!text) return "";
  return text.replace(/([()])/g, "\\$1");
};

export const removeHtml = (html) => {
  if (!html) return "";
  const div = document.createElement("DIV");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export function currency(value) {
  if (!value) return "R$ 0,00";
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(isNaN(value) ? 0 : value);
}